.cv_date_picker {
  width: 100%;
  height: 32px;
  border: none;
  background-color: white;

  .react-date-picker__wrapper {
    width: 100%;
    border: none;

    input {
      border: none;
      height: inherit;
    }
    input:disabled {
      background-color: white;
    }
  }
}

.react-date-picker--disabled {
  background-color: white !important;
}

// Comment if default style is wanted
.cv_date_picker_calendar {
  width: 250px !important;
  height: 250px !important;

  .react-calendar__year-view, .react-calendar__decade-view, .react-calendar__century-view {
    button {
      padding: 1.2em 0.5em;
    }
  }

  .react-calendar__century-view {
    button {
      padding: 0.7em 0.5em;
    }
  }

  .react-calendar__month-view {
    button {
      padding: 0.5em 0.5em;
    }
  }
}