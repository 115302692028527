.sidebar__link p { 
    width:200px;
}
.ltr-support .sidebar__link p {
    left: 36px;
}
.sidebar__link {
    height: 36px;
    width: 240px;
    transition: all 0.3s;
    position: relative;
    cursor: pointer;
    display: flex;
    padding: 11px 15px;
    overflow: hidden;
    background: transparent;
    border: none;
}
.sidebar__link-active p { 
    width:200px;
    font-weight: bold;
}



