.form__form-group-field.with_image img {
    width: 150px !important;
    height: auto !important;
    border-radius: 6px;
    margin-left: 0px;
    margin-bottom: 5px;
}

.toast {
    max-width: 800px !important;
    max-height: 500px !important;
    
}