.select2-container{
    width: 100% !important;
}
.select2-container--default .select2-selection--multiple{
    border-color: #ebedef
}

.select2-container--default.select2-container--focus .select2-selection--multiple{
    border-color: #4CE1B6
}

.select2-container--default .select2-search--inline .select2-search__field {
    height:1rem;
}
