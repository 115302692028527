.event-massive-load {
  .loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: 120px;
    }
  }
  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}