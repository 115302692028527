.professional_list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .professional_list_item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 2px;
    border-bottom: 1px solid #eff1f5;

    .professional_list_item-name {
      display: flex;
      flex: 3;
      padding: 10px 0 10px 0;
      label {
        margin-bottom: 0;
      }
    }

    .professional_list_item-role {
      display: flex;
      flex: 2;
      padding: 10px 0 10px 0;
      label {
        margin-bottom: 0;
      }
    }

    .professional_list_item-actions {
      display: flex;
      flex: 1;
      padding: 10px 0 10px 0;
      label {
        margin-bottom: 0;
      }
    }
  }

  .no_professionals {
    display: flex;
    justify-content: center;
    align-items: center;

    h5 {
      margin-left: 5px;
    }
  }

  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.add_new_professional {
  margin-top: 20px;

  .add_new_professional-result {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 2px;
    border-bottom: 1px solid #eff1f5;
    margin-top: 15px;

    .add_new_professional-result-name {
      display: flex;
      flex: 3;
      padding: 10px 0 10px 0;
      label {
        margin-bottom: 0;
      }
    }

    .add_new_professional-result-role {
      display: flex;
      flex: 2;
      padding: 10px 0 10px 0;
      label {
        margin-bottom: 0;
      }
    }

    .add_new_professional-result-actions {
      display: flex;
      flex: 1;
      padding: 10px 0 10px 0;
      label {
        margin-bottom: 0;
      }
    }
  }

  .error_container {
    margin-top: 15px;

    label {
      color: $color-red;
    }
  }
}