.animal-details-row {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  font-family: Roboto, sans-serif;
  color: #646777;

  .value {
    margin-left: 5px;
  }
}

.animal-massive-load {
  .loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: 120px;
    }
  }
  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}