.address_input {
  &.horizontal {
    width: calc(50% - 10px) !important;
    margin-right: 10px !important;
    //margin-top: 10px !important;
    &.full_register_form {
      max-width: 50%;
      width: 100%;
      padding-left: 15px;
    }
  }
  &.vertical {
    width: 100% !important;
    //margin-top: 10px !important;
  }
}

.full_register_form {
  width: calc(50% - 10px) !important;
  padding-left: 15px;
  margin-right: 10px !important;

  .react-date-picker {
    border: none;
  }
}
