.details-row {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  font-family: Roboto, sans-serif;
  color: #646777;

  .value {
    margin-left: 5px;
  }
}