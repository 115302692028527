.cv__table {
  overflow: visible;
  .rdg-last--frozen {
    box-shadow: none !important;
  }

  .cv-table-title {
    display: flex;
    justify-content: space-between;
  }

  .cv-table-subtitle {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .mdi-icon {
      margin-left: 10px;
    }
  }

  .subtitle__wrapper {
    position: relative;
    display: flex;
    height: 60px;
  }

  .subtitle__right {
    position: absolute;
    @include directify($directions) {
      #{directed('right')}: 0px;
      #{directed('margin-right')}: 15px;
    }
    display: flex;
    height: 100%;
  }

  .subtitle__left {
    position: absolute;
    @include directify($directions) {
      #{directed('left')}: 0px;
    }
    display: flex;
    height: 100%;
    width: 50%;
  }

  .column-filter {
    margin-left: 10px;

    .column-filter-menu {
      position: absolute;
      z-index: 999;
      right: 0;
      padding: 10px;
      background-color: rgb(242,244,247);
      border-radius: 5px;

      .form {
        justify-content: center;
        flex-direction: column;
      }
    }
  }
  .loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: 120px;
    }
  }
}

.react-select {
  display: block;
  max-height: 30px !important;
  min-width: 100px;
}

.multi-select {
  --rmsc-main: #4ce1b6 !important;
  --rmsc-hover: #70bbfd !important;
  --rmsc-selected: #f2f4f7 !important;
  --rmsc-border: #ccc;
  --rmsc-gray: #aaa;
  --rmsc-bg: #fff;
  --rmsc-p: 10px; /* Spacing */
  --rmsc-radius: 4px; /* Radius */
  --rmsc-h: 23px !important; /* Height */
  max-width: 180px;
  min-width: 180px;
}