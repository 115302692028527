.establishment_type_selection {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .title {
    margin-bottom: 20px;
  }

  .establishment_type_button {
    margin-right: 0;
  }
}