.account {
  height: 100vh;
  width: 100%;
  min-height: 100vh;
  display: flex;
  overflow-y: auto;

  @include themify($themes) {
    background: url(../shared/img/cow.jpg) no-repeat center;
    background-size: cover;
  }
}

.account__wrapper {
  @include directify($directions) {
    text-align: directed('left');
  }
  margin: auto;
  padding: 10px;
}

.account__card {

  @include themify($themes) {
    background-color: rgba(255, 255, 255, 0.9);
  }

  background-color: #ffffff;
  padding: 50px 60px;
  max-width: 520px;
  width: 100%;
  &.full_register_card {
    max-width: 720px;
    max-height: 90vh;
    overflow: scroll;
    &.account__wizard {
      padding: 20px 30px;

    }
  }
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 120px;
    }
  }
}

.account__profile {
  text-align: center;
}

.input-error-container {
  width: 100%;
  display: flex;
  align-items: center;
  height: 30px;
  .error-message {
    color: red;
  }
}

.error-checkbox {
  width: 100%;
  display: block;
  align-items: center;
  height: 0px;
  margin-top: 20px;
  .error-message {
    color: red;
  }
}

.error-container {
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 50px;
  .error-message {
    color: red;
  }
}

.account__btns {
  display: flex;
  width: calc(100% + 10px);
  @include directify($directions) {
    #{directed('margin-right')}: 0px;
    #{directed('margin-left')}: -10px;
  }
  margin-top: -10px;
  margin-bottom: -20;

  a, button {
    @include directify($directions) {
      #{directed('margin-right')}: 0px;
      #{directed('margin-left')}: 10px;
    }
    margin-top: 10px;
    margin-bottom: 20px;
    white-space: nowrap;
  }
}

.account__btn {
  width: 100%;
  @include directify($directions) {
    #{directed('margin-right')}: 0px;
  }
}

.account__avatar {
  height: 64px;
  width: 64px;
  border-radius: 50%;
  overflow: hidden;
}

.account__name {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 15px;
  margin-top: 5px;
}

.account__sub {
  margin-top: 0;
  margin-bottom: 10px;
  color: $color-additional;
  font-size: 11px;
  line-height: 15px;
}

.account__forgot-password {
  position: absolute;
  font-size: 11px;
  line-height: 15px;
  bottom: -18px;
  @include directify($directions) {
    #{directed('right')}: 0px;
  }

  a, span {
    color: $color-blue;
    cursor: pointer;

    &:hover {
      color: $color-blue-hover;
      text-decoration: none;
    }
  }
}

.form__form-group--forgot {
  margin-bottom: 40px;
}

.account__or {
  text-align: center;
  margin-top: 35px;
  margin-bottom: 20px;
  position: relative;

  &:before, &:after {
    content: "";
    height: 1px;
    width: calc(50% - 90px);
    background: #dddddd;
    position: absolute;
    top: 10px;
  }

  &:before {
    @include directify($directions) {
      #{directed('left')}: 0px;
    }
  }

  &:after {
    @include directify($directions) {
      #{directed('right')}: 0px;
    }
  }
}

.account__social {
  text-align: center;
}

.account__social-btn {
  display: inline-block;
  height: 38px;
  width: 38px;
  border-radius: 5px;
  padding: 9px;
  @include directify($directions) {
    #{directed('margin-right')}: 10px;
  }
  transition: all 0.3s;
  border: none;

  &:last-child {
    @include directify($directions) {
      #{directed('margin-right')}: 0px;
    }
  }

  svg {
    fill: #ffffff;
    height: 20px;
    width: 20px;
  }

  &.account__social-btn--facebook {
    background: #4766a4;

    &:hover {
      background: darken(#4766a4, 10%);
    }
  }

  &.account__social-btn--google {
    background: #c74d4d;

    &:hover {
      background: darken(#c74d4d, 10%);
    }
  }
}

.account__head {
  margin-bottom: 30px;
  @include directify($directions) {
    #{directed('padding-left')}: 10px;
  }
  display: flex;
  justify-content: center;
  img {
    max-width: 300px;
  }
}

.account__logo {
  font-weight: 700;
}

.account__logo-accent {
  color: $color-blue;
}

.account__have-account {
  text-align: center;
  margin-top: 20px;

  a {
    color: $color-blue;
    transition: all 0.3s;

    &:hover {
      color: $color-blue-hover;
      text-decoration: none;
    }
  }
}

.role__selection__modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 48px;
  padding-right: 48px;
  button {
    width: 100%;
  }
  .license_input {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    input {
      flex: 5;
      margin-right: 30px;
      padding: 5px 10px;
      font-size: 12px;
      height: 32px;
      border: 1px solid #f2f4f7;
      background-color: white;

      &:focus, &:active {
        outline: none;
        border-color: #4ce1b6;
      }
    }

    Button {
      flex: 1;
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: 520px) {

  .account__card {
    padding: 35px 30px;
  }
}

@media screen and (max-width: 425px) {

  .account__btns {
    flex-wrap: wrap;
  }
}
