.form__form-group-file label {
    border-radius: 2px;
    line-height: 18px;
    font-size: 12px;
    padding: 4px 20px;
    cursor: pointer;
    transition: all 0.3s;
    text-align: center;
    border: dotted;
}
.input-error-container {
    margin-top: 0px;
}

.form__form-group-file label {
    margin-bottom: 0px;
}

.toasts-container {
    max-height: 150px;
}

.modal-content {
    padding: 0px;
}

.card__title {
    margin-bottom: 10px;
}

table tbody tr th {    
    padding: 0px 0px 0px 0px !important;    
}

table tbody tr td {    
    padding: 0px 0px 0px 0px !important;    
}

.nav-link {
    cursor: pointer;
}