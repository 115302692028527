
  
  /* Prefix */    
    label {
        margin-bottom: 1px;
    }
    
    .checkboxReport {
        padding:1px !important;
        vertical-align: bottom !important;
    }
    
    label > span > a {
        font-size: 12px !important;
        color: darkblue;
        margin-bottom: 5px !important;
    }
    

    

    