.search_input {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 5px;
  border: 1px solid darkgrey;
  margin: auto;
  max-width: 90%;

  input {
    border: none;
    flex: 6;
  }

  input:focus {
    outline: none;
  }

  button {
    border: none;
    flex: 1;
    svg {
      margin: auto;
      height: 18px;
      width: 18px;
      fill: #646777;
    }
  }
}