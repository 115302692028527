.generate__mnemonic, .verify__mnemonic {
  .subhead {
    margin-bottom: 6px;

    &.warning {
      color: #ff8300;
      margin-bottom: 20px;
    }
  }

  .button__container {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }

  .card-body {
    //height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .mnemonic__container__title {
      color: #646777;
      font-size: 14px;
      font-weight: 500;
      margin-top: 5px;
      margin-bottom: 5px;
    }

    .mnemonic__container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 10px;
      margin-bottom: 10px;

      &.min_height {
        min-height: 58px;
      }

      &.border {
        border-radius: 10px;
        padding: 10px
      }

      .mnemonic__word {
        font-size: 18px;
        color: #696969;
        padding-left: 4px;
        padding-right: 4px;
        margin: 4px;
        background-color: #4ce1b6;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  }
}